const operatorFunctions = {
	'==': (v1, v2) => v1 == v2,
	'===': (v1, v2) => v1 === v2,
	'<': (v1, v2) => v1 < v2,
	'<=': (v1, v2) => v1 <= v2,
	'>': (v1, v2) => v1 > v2,
	'>=': (v1, v2) => v1 >= v2,
	'&&': (v1, v2) => v1 && v2,
	'||': (v1, v2) => v1 || v2,
};

export default function (v1, operator, v2, options) {
	const compare = operatorFunctions[operator];
	const result = compare ? compare(v1, v2) : false;
	return result ? options.fn(this) : options.inverse(this);
}
